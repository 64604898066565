













import { User } from "@/models/user.model";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import UserListCard from "../components/users/UserListCard.vue";

const usersModule = namespace("Users");

@Component({ components: { UserListCard } })
export default class Leaderboard extends Vue {
  isLoading = true;

  @usersModule.Getter("leaderboard")
  leaderboard!: User[];

  @usersModule.Action("fetchLeaderboard")
  fetchLeaderboard!: () => Promise<void>;

  async created() {
    this.isLoading = true;
    await this.fetchLeaderboard();
    this.isLoading = false;
  }
}
